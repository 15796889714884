import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import styled from '@emotion/styled';
import { TitleSecondary } from '@components/styled/Typography';
const Container = styled.div(props => ({
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: `${props.theme.customColors.surface.surface}`,
    padding: `${props.theme.spacing_sizes.s * 5}px 
                ${props.theme.spacing_sizes.s * 5}px  
                ${props.theme.spacing_sizes.s * 3}px`,
    borderRadius: `${props.theme.spacing_sizes.xs * 1.25}px`,
    boxShadow: '0px 4px 8px -6px rgba(24, 39, 75, 0.04)',
    gap: `${props.theme.spacing_sizes.l}px`,
}));
const BlockTitle = styled(TitleSecondary)(props => ({
    lineHeight: '22px',
    padding: `${props.theme.spacing_sizes.xs * 0.5}px 0`,
}));
const SettingsBlockContainer = ({ title = undefined, children = null, className = undefined, gap = undefined, padding = undefined, }) => (_jsxs(Container, { className: className, style: {
        gap,
        padding,
    }, children: [!!title && (_jsx(BlockTitle, { children: title })), children] }));
export default SettingsBlockContainer;
